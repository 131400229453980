
.lesson-card {
  border: 2px solid #78bdda !important;
  background-color: #fbfbfb !important;
  margin-bottom: 2rem;
  overflow: hidden;
}
.lesson-card-header {
  background-color: #78bdda !important;
  padding: 0.3rem 1.25rem !important;
}
.lesson-card-body {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-tag-and-file {
  font-size: small;
}
.card-tag-and-file .tag-item {
  display: inline-block;
  margin-right: 5px;
}
.lesson-card-header:first-child {
  border-radius: 0 !important;
}
.lesson-card-footer {
  background-color: #e8f4f9 !important;
  display: flex;
  justify-content: space-between;
  font-size: small;
  padding: 0.3rem 0.625rem !important;
  border-top: unset !important;
}
.lesson-card-footer:last-child {
  border-radius: 0 !important;
}
.lesson-card-tag {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  max-height: 25px;
  max-width: 1200px;
  overflow-x: auto;
}

.scrollable-card-body {
  max-height: 400px;
  overflow-y: auto;
}

.footer-items-author-and-from {
  margin-right: 5px;
}

.lesson-card-footer-items {
  display: flex;
  align-items: center;
}

.lesson-card-footer-items button {
  padding: 5px;
}

.lesson-card-nav {
  display: flex;
  color: white;
  height: 50px;
  flex-direction: column;
}
.lesson-card-nav-item {
  font-size: small;
}
.nav-item-title {
  font-weight: bold;
  font-size: small;
}

.nav-title-and-date {
  display: flex;
  justify-content: space-between;
}
.card-tag-and-file {
  color: #78bdda;
}

.card-scrollable {
  max-height: 300px;
  overflow-y: auto;
}
.tag-item {
  font-size: small;
  white-space: nowrap;
}
.lesson-file {
  font-size: small;
}
.lesson-summary button {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e8f4f9;
  width: 80px;
  margin-right: 5px;
  font-size: 14px;
}
.lesson-summary textarea {
  height: 10rem;
}
.scrollable-card-body::-webkit-scrollbar {
  width: 4px;
}
.scrollable-card-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.card-tag::-webkit-scrollbar-horizontal {
  width: 4px;
}

.expand-lessons {
  background-color: #f2f2f266 !important;
  padding: 5px;
  font-size: small;
  margin-bottom: 5px;
  color: #495057;
  margin-right: 5px;
  border-radius: 5px;
}
.validation-error {
  color: red;
  font-size: small;
  display: block;
  margin-bottom: 10px;
}
.editable-lesson-summary.error {
  border: 1px solid red;
}

@media (max-width: 768px) {
  .lesson-card-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .lesson-card-nav {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .lesson-card-footer-items {
    justify-content: flex-start;
  }
}
validation-error {
  color: red;
  font-size: small;
  display: block;
  margin-bottom: 10px;
}
.editable-lesson-summary.error {
  border: 1px solid red;
}

@media (max-width: 570px) {
  .form-select-container {
    flex-direction: column;
  }
  .card-footer {
    font-size: small;
  }
}
@media (max-width: 767px) {
  .card-text {
    padding: 10px;
    font-size: small;
  }
}

@media (max-width: 859px) {
  .nav-title-and-date {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .nav {
    height: auto;
  }

  .tag-item {
    display: block;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .lesson-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-items-actions {
    justify-content: flex-start;
  }
}
