/* Enhanced Aesthetic CSS for Activity List */

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #333;
}

h1 {
  text-align: center;
  margin: 20px 0;
  color: #1e3a8a;
  font-size: 2.5em;
  font-weight: 600;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 30px 0;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #1f2937;
}

.filters input {
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-top: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.filters input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 8px rgba(59, 130, 246, 0.3);
}

.activity-list {
  max-width: 900px;
  margin: 0 auto;
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.activity-list ul {
  list-style-type: none;
  padding: 0;
}

.activity-list li {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: #f9fafb;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.activity-list li:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.activity-list li strong {
  color: #1e3a8a;
  font-size: 1.2rem;
}

.activity-list li span {
  color: #4b5563;
}

.activity-list p {
  text-align: center;
  color: #6b7280;
  font-size: 1.1rem;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .filters label {
    width: 100%;
  }

  .activity-list {
    padding: 20px;
  }

  .activity-list li {
    flex-direction: column;
    padding: 10px;
  }
}