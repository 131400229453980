.records_modal_table_container {
  overflow: scroll;
  height: 75vh;
  text-align: center;
  font-size: small;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .records_modal_table_container {
    font-size: medium;
  }
}