.team-task-progress-follow-up {
  box-sizing: border-box;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid green;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  outline: none;
  background-color: #e1f3e2;
}
.team-task-progress-follow-up-red {
  border: 2px solid red;
  background-color: #f3e1e2;
}
.team-task-progress-follow-up-check {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
  font-size: 10px;
  color: green;
}
@media screen and (max-width: 1240px) {
  .team-task-progress-follow-up {
    box-sizing: border-box;
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    justify-self: end;
    width: 15px;
    height: 15px;
  }
}