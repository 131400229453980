.followup-tooltip-container {
  position: relative;
  display: inline-block;
}

.followup-tooltip-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.followup-tooltip-button-icon {
  font-size: 16px;
}

.followup-tooltip {
  visibility: hidden;
  width: 440px;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 15px;
  position: fixed;
  z-index: 1000;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  max-height: 100vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.followup-tooltip-button:hover .followup-tooltip {
  visibility: visible;
  opacity: 1;
}

.followup-tooltip-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .followup-tooltip {
    width: 90%;
    max-width: 400px;
    left: 50%;
    overflow-y: auto;
    max-height: 20vh;
  }
}