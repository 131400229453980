/* Skeleton Loading for Timelog */

.skeleton-loading-timelog {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 1rem;
  background-color: #f3f4f6;
}

.skeleton-loading-item-timelog {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  height: 20px;
  border-radius: 5px;
  margin: 0.625rem 18.75rem 0.125rem 2.5rem;
}

.skeleton-loading-item-add-intangible {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 3s ease-in-out infinite;
  height: 2rem;
  border-radius: 5px;
  margin: 1rem 5rem 0.625rem 32rem;
  width: 15rem;
}

/* Skeleton Loading for Timelog Filter */

.skeleton-loading-timelog-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skeleton-loading-timelog-filter-item {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-left: 15px;
  padding-left: 15px;
}

/* Skeleton Loading for Team Member Tasks */

.skeleton-loading-team-member-tasks-row {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  height: 60px;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
}

/* Skeleton Loading for Weekly Summary Due Date (click to add) */

.skeleton-loading-weekly-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f3f4f6;
  width: 16rem;
  height: 8rem;
  margin: 1.5rem 0;
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  border-radius: 5px;
}

/* Skeleton Loading for Weekly Summaries Report Page */

.skeleton-loading-weekly-summaries-report {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: white;
  margin-top: 3rem;
  padding-top: 1rem;
}

.skeleton-loading-weekly-summaries-report-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f3f4f6;
  width: 35rem;
  height: 1.5rem;
  margin: 0.125rem 0 0.125rem 2rem;
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  border-radius: 5px;
}

/* Skeleton Loading for User Profile Page */

.skeleton-loading-user-profile-picture {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f3f4f6;
  width: 16rem;
  height: 8rem;
  margin-top: 2.5rem;
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  border-radius: 5px;
}

.skeleton-loading-user-profile-item {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  height: 1.5rem;
  width: 42rem;
  border-radius: 5px;
  margin: 0.625rem 18.75rem 0.125rem 2.5rem;
}

/* Skeleton Loading for User Management */

.skeleton-loading-user-management-item {
  background: linear-gradient(90deg, #a0a0a0 0%, #c0c0c0 50%, #a0a0a0 100%);
  background-size: 200% 100%;
  animation: skeleton-loading-animation 1.5s ease-in-out infinite;
  height: 2.5rem;
  border-radius: 5px;
  margin: 0.625rem 0.25rem 0.125rem 0.25rem;
}

@keyframes skeleton-loading-animation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
