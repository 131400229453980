.questionnaire-info {
  width: 50%;
  margin: 10px auto;
  border: 1px solid #c0c0c0;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.questionnaire-info h1 {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
}

.questionnaire-info p {
  line-height: 1.2;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0;
}

.questionnaire-info ol {
  text-align: left;
  padding-left: 20px;
  margin: 0 30px;
}

.questionnaire-info hr {
  border: none;
  border-top: 2px solid #ddd;
  margin: 10px 0;
}

.blue-strip {
  height: 20px;
  background-color: #5082a7;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.edit-link {
  cursor: pointer;
  color: #2a31fc;
  text-decoration: underline;
  margin: 30px;
}



@media (max-width: 768px) {
  .questionnaire-info {
    width: 80%;
    margin: 10px auto;
  }

  .questionnaire-info h1,
  .questionnaire-info p,
  .questionnaire-info ol {
    margin-left: 20px;
    margin-right: 20px;
  }

  .blue-strip {
    height: 15px;
  }

  .edit-link {
    margin: 20px;
  }
}

@media (max-width: 480px) {
  .questionnaire-info {
    width: 90%;
  }

  .questionnaire-info h1 {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .questionnaire-info p {
    font-size: 0.9rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .questionnaire-info ol {
    margin-left: 10px;
    margin-right: 10px;
  }

  .blue-strip {
    height: 12px;
  }

  .edit-link {
    margin: 10px;
  }
}
