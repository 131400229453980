.general-questions {
  width: 50%;
  margin: 10px auto;
  border: 1px solid #c0c0c0;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .general-questions {
    width: 80%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .general-questions {
    width: 90%;
  }
}

/* .general-questions h1 {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
} */

.general-questions p {
  line-height: 1.2;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0;
}

/* .general-questions hr {
  border: none;
  border-top: 2px solid #ddd;
  margin: 10px 0;
} */

.blue-strip {
  height: 50px;
  background-color: #5082a7;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 5px;
}
.hours{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.hours-radio {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px;
  margin-left: 32px;
  overflow-x: auto;
}

.hours-radio label {
  margin: 0;
}

.hours-radio input[type="radio"] {
  margin-right: 2px;
  margin-bottom:0;
}

.standup{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.standup-radio {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px;
  margin-left: 32px;
}

.standup-radio label {
  margin: 0;
}

.standup-radio input[type="radio"] {
  margin-right: 2px;
  margin-bottom:0;
}

.period{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.period-radio {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin:20px;
  margin-left: 32px;
}

.period-radio div {
  display: flex;
  align-items: center;
}

.period-radio label {
  margin: 0;
}

.period-radio input[type="radio"] {
  margin-right: 10px;
  margin-bottom:0;
}

.question {
  color: #2f5061;
  margin: 20px;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.locations {
  display: flex;
  flex-direction: column;
}

.locations input[type="text"] {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 30px;
  background-color: #e6f5f3;
}

.radio-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  margin: 10px 60px;
}

.radio-rating div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-rating label {
  margin-bottom: 2px;
}

@media screen and (max-width: 1024px) {
  .radio-rating div {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .radio-rating div {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .radio-rating div {
    width: 100%;
  }
}

.preferences-checkbox {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
  margin-bottom:10px;
}

.preferences-checkbox div {
  display: flex;
  align-items: center;
}

.preferences-checkbox input[type="checkbox"] {
  margin-right: 5px;
  margin-left: 30px;
  margin-bottom: 0;
}

.preferences-checkbox label {
  margin: 0;
}

.availability-selector {
  font-family: Arial, sans-serif;
  text-align: left;
  max-width: 800px;
  margin: 30px;
  overflow-x: auto;
}

.availability-grid {
  display: grid;
  grid-template-columns: 1fr repeat(9, 1fr);
  gap: 5px;
  align-items: center;
}

.time-cell {
  text-align: center;
  margin-bottom: 5px;
}

.day-label {
  text-align: left;
  grid-column: 1 / 2;
}

.availability-checkbox {
  display: inline-block;
  margin: 5px auto;
  grid-column: span 1;
}

.question-container{
  width:100%;
}

.edit-question-container {  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f9f9f9;
  transition: all 0.3s ease-in-out;
  border: 1px solid #5082a7;
  border-radius: 10px;
  width: 95%;
  margin-left:auto;
  margin-right:auto;
  padding:10px;
}

.edit-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top:0.5rem;
  color: #2f5061;
}

.edit-question {
  display: flex;
  align-items: center;
}

.edit-input {
  flex: 1;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  margin: 5px;
  margin-left:0;
}

.edit-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  outline: none;
}

.save-icon {
  cursor: pointer;
  color: green;
  font-size: 1.5rem;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.save-icon:hover {
  color: #006400;
  transform: scale(1.2);
}

.edit-icon {
  cursor: pointer;
  color: #5082a7;
  margin-left: 5px;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  margin-bottom: 0.2rem;
}

.edit-icon:hover {
  color: #0056b3;
  transform: scale(1.2);
}

.edit-icon:active {
  color: #003f7f;
  transform: scale(1.1);
}

.spinner-hgnform{
  position: fixed;
  top: 55%;
  left: 50%;
  width:4rem !important;
  height:4rem !important;
  border-width: 5px !important; /* Adjust the thickness */
  border-color: white !important; /* Change to your desired color */
  border-right-color: #5082a7 !important; /* Keeps animation effect */
}