.teamtable-container,
.projecttable-container {
  background-color: #fafafa;
}

.projecttable-container .col-md-2,
.teamtable-container .col-md-2 {
  padding: 0px;
}

.container {
  padding: 0;
}

.row {
  margin-left: 0;
}

.teamtable-container .container,
.projecttable-container .container {
  padding: 0px;
}

.teamtable-container .row,
.projecttable-container .row {
  width: 100%;
  margin: 0px;
}

.btn-addteam,
.btn-addproject {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  z-index: 10;
}

.teams-span,
.projects-span {
  font-size: x-large;
  font-weight: 700;
}

.project-auto-complete {
  cursor: pointer;
  padding: 5px;
}

.project-auto-complete:hover {
  background: #ddebff;
}

.bg-darkmode-liblack .project-auto-complete:hover {
  background: #000000;
}

.team-auto-complete {
  cursor: pointer;
  padding: 5px;
}

.team-auto-complete:hover {
  background: #ddebff;
}

#myTabContent {
  margin-top: 20px;
  padding-top: 4px;
  border-color: #dee2e6;
}

@media (max-width: 1024px) {
  #myTabContent {
    padding-top: 0px;
    border-color: #fff #dee2e6 #dee2e6;
  }
}