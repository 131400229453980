.team-member-tasks {
  background-color: white;
  overflow: hidden;
}

.team-member-tasks h1 {
  color: black;
  font-size: 20px;
}

.task_table-container {
  overflow: hidden;
  position: relative;
}

.task_table-container table {
  table-layout: fixed;
  width: 100%;
}


.team-member-tasks-bell {
  margin-right: 10px;
  color: red;
  cursor: pointer;
}

.team-member-tasks-done {
  /* margin-left: 10px; */
  color: green;
  cursor: pointer;
}

.team-member-task-remove {
  margin-left: 10px;
  margin-right: 10px;
  color: red;
  cursor: pointer;
}

.team-member-task-info {
  cursor: pointer;
}

.task-info-modal-backdrop {
  background-color: grey;
  opacity: 0.5;
}

.committed-hours-circle {
  padding: 0.75rem;
}

.team-member-tasks .team-member-tasks-headers {
  vertical-align: middle;
}

.team-member-tasks .team-member-tasks-subtable {
  margin: 0;
  text-align: center;
}

.team-task-progress {
  position: relative;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.task-break {
  border-bottom: 1px solid lightgray;
}

.task-break:last-of-type {
  border-bottom: none;
}

.dark-teammember-row th{
  background-color: #3a506b;
  color: white;
}
.light-teammember-row th{
  background-color: #f0f8ff;
  color: black;
}

.task-align {
  text-align: left;
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  gap: 8px;
  /* Space bet*/
}

.team-member-tasks-user-name {
  width: 50%;
}

.team-member-tasks-user-report-link  .team-member-tasks-user-report-link-image{
   width: 20px;
   height: 20px;
   margin: 0 0 5px 5px;
}
.deadlineCount {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  background: #ff4d4f;
  border-radius: 50%;
  color: #fff;
  height: 25px;
  font-size: 15px;
  width: 25px;
  top: 3px;
  right: -11px;
}

.team-clocks {
  display: flex;
  justify-content: space-around;
}

.team-member-tasks-number{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background: #ff4d4f;
  border-radius: 50%;
  color: #fff;
  height: 22px;
  font-size: 14px;
  width: 22px;
  margin: 0 0 5px 5px;
}
.dashboard-team-clocks{
  color: #339CFF !important;
}

.team-clocks-header {
  height: 73.5px;
  align-items: center;
}

.hours-btn-container {
  display: flex;
  align-items: center;
}

.circle-border {
  border-radius: 6px;
  width: 42px;
  height: 42px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  box-shadow: 2px 2px 4px 1px lightgray;
}

.header-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}

#taskboard-card-header-title {
  font-size: 1.5rem;
}

.complete-task-button {
  background-color: lightgreen;
}

.uncomplete-task-button {
  background-color: lightcoral;
}

.markAsDoneButton:hover {
  cursor: pointer;
}

.right-padding-temp-fix {
  padding-right: 0 !important;
}

.table-row {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.taking-time-off-content-div {
  background-color: rgba(0, 0, 25, 0.7);
  height: 100%;
  justify-items: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.row-content {
  position: relative;
  height: 100%;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.remove-padding {
  padding: 0 !important;
}

.taking-time-off-table-div:hover {
  background-color: rgba(0, 0, 25, 0.6);
}

.taking-time-off-content-text {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  color: white;
  margin: 0;
  box-sizing: border-box;
  padding-right: 10px;
}

.show-time-off-btn {
  border-radius: 6px;
  height: 42px;
  width: 42px;
  border: 1px solid #17a2b8;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  box-shadow: 2px 2px 4px 1px lightgray;
  box-sizing: border-box;
  /* Icon group centering */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.show-time-off-calender-svg {
  position: relative;
}

.show-time-off-icon {
  position: absolute;
  top: 6px;
  right: 6px;
}

.taking-time-off-content-btn {
  color: white;
  box-sizing: border-box;
  border: 1px solid white;
  padding: 7px;
  border-radius: 6px;
}

.taking-time-off-content-btn:hover {
  background-color: rgba(0, 0, 25, 0.8);
}

.time-off-detail-modal {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.time-off-detail-modal::-webkit-scrollbar {
  width: 12px;
}

.time-off-detail-modal::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.time-off-detail-modal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #007BFF;
  box-shadow: inset 0 0 3px #e7e7e7;
}


.time-off-detail-modal-section {
  margin-bottom: 10px;
}

.compress-time-off-detail-button {
  color: white;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  justify-self: end;
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.compress-time-off-detail-button:hover,
.expand-time-off-detail-button:hover {
  background-color: rgba(0, 0, 25, 0.6);
}

.grid-container {
  display: grid;
  grid-template-rows: 1fr auto;
}

.expand-time-off-detail-button {
  color: white;
  background-color: rgba(0, 0, 25, 0.7);
  border-radius: 5px;
  justify-self: end;
  margin-right: -12px;
  margin-bottom: -12px;
}

.time-off-detail-modal-card-container {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
}

.team-member-tasks-icons {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  /* Adjust the gap as needed */
}

.team-member-tasks-content {
  align-items: center;
  /* Vertically center-align children */
  gap: 8px;
  /* Space between children */
}

.team-task-progress-container {
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  column-gap: 3px;
}

.team-task-progress-time {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;

}

.team-task-progress-time-volunteers {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  justify-self: center;
}

.followup-tooltip-container {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
}

.team-task-progress-bar {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  margin-top: 5px;
}

.task_table-container {
  width: 100%;
  overflow-y: hidden;
}

.hours-btn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team-selector-container {
  display: flex;
  flex-direction: row;
}

.container .google-icon-wrapper img {
  width: 24px;
  height: auto;
}


.name {
  margin-bottom: 4px;
  margin-right: 4px;
  width: 2.5rem;
  display: flex;
  justify-content: center;
}

.name-initial {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.name-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.member-links-wrapper {
  display: flex;
  align-items: center;
}

.timelog-info {
  width: 2.5rem;
  display: flex;
  justify-content: center;
}

.dark-mode.multi-select-filter .dropdown-container {
  background-color: #1C1C1C !important;
}

.rmsc.dark-mode .dropdown-content {
  background-color: #1C1C1C !important;
  color: #fff;
}

.rmsc.dark-mode .dropdown-content .panel-content {
  background-color: #1C1C1C !important;
  color: #fff !important;
}

.rmsc.dark-mode .select-item:hover {
  background: #252525 !important;
  color: #fff !important;
}

.rmsc.dark-mode .select-item.selected {
  background: #252525 !important;
  color: #fff !important;
}

.rmsc.dark-mode .select-item {
  color: #fff !important;
}

.rmsc.dark-mode .search input:focus {
  background: #252525 !important;
  color: #fff !important;
}

@media (max-width: 1240px) and (min-width: 992px) {
  .header-box {
    flex-direction: column;
  }

  .hours-btn-container {
    margin-top: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  .team-task-progress-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .team-task-progress-time,
  .team-task-progress-time-volunteers {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  .followup-tooltip-container {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
    justify-self: start;
  }

  .team-task-progress-bar {
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
    margin-bottom: 3px;
  }

  .team-member-tasks-bell {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {

  /* Adjust 768px to the breakpoint you want */
  .team-task-progress {
    width: 100%;
    /* or any other value that fits your design */
    min-width: auto;
    max-width: none;
  }

  .task-table {
    max-height: 900px;
  }
}

@media only screen and (max-width: 768px) {
  .pc-component {
    display: none;
  }

  .team-member-tasks h1 {
    color: black;
    font-size: 20px;
    text-align: center;
  }

  .hours-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 165px;
    padding: 0 5%;
  }



  .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .card-body .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .team-member-tasks .table,
  .team-member-tasks .table tbody,
  .team-member-tasks .table tr,
  .team-member-tasks .table td {
    display: block;
    width: 100%;
    text-align: left !important;
  }

  .team-member-tasks .table td::before {
    content: attr(data-label);
    font-size: 17px;
    font-weight: 500;

    display: block;
    text-align: left !important;
  }

  .table-row {
    border: solid #e9ecef 2px;
    background: #faf7fc;
    font-size: 0.75rem !important;
  }

  .table-row td {
    padding: 2px !important;
  }

  .spacer {
    height: 20px;
  }

  .deadlineCount {
    right: 2px;
  }

  .expand-time-off-detail-button {
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .team-member-tasks h1 {
    font-size: 24px;
  }

  /* .header-box {
    flex-direction: unset;
  } */

  .team-member-tasks h1 {
    font-size: 28px;
  }

  .remove-child-borders>td {
    border-top-width: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-view-select {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hours-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-view-select {
    width: 63px;
    color: white;
  }

  .responsive-btn-size {
    display: none;
  }
}

@media only screen and (max-width: 530px) {
  .hours-btn-container {
    padding: 0;
  }

  .team-selector-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 440px) {
  .hours-btn-container {
    column-gap: 0.2em;
  }

  .team-member-tasks h1 {
    color: black;
    font-size: 14px;
    text-align: center;
  }

}

@media only screen and (max-width: 544px) {
  .responsive-font-size {
    font-size: 0.75rem !important;
  }

  .multi-select-filter {
    width: 50%;
  }
}

@media screen and (max-width: 1075px) and (min-width: 992px) {
  .header-box h1 {
    font-size: 1.25rem !important;
    margin-right: 10px;
  }

  .team-member-task-remove {
    margin: 0;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .team-member-tasks-user-name-link {
    font-size: 14px !important;
  }

  .hours-completed-column {
    width: 50px;
  }

  .team-clocks {
    font-size: 0.75rem !important;
  }

  .team-member-tasks-content-link {
    font-size: 0.75rem !important;
  }

  .team-task-progress-time {
    font-size: 0.75rem !important;
  }

  .team-task-progress-time-volunteers {
    font-size: 0.75rem !important;
  }
}