.date-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-body-shadow {
  box-shadow: 2px 2px 4px 1px lightgray;
}

.card-header-shadow {
  box-shadow: 2px 2px 4px 1px lightgray;
  margin-bottom: 1px;
}

.card-body-shadow-dark {
  box-shadow: 2px 2px 4px 1px black;
}

.card-header-shadow-dark {
  box-shadow: 2px 2px 4px 1px black;
  margin-bottom: 1px;
}

.time-entry-container {
  display: flex;
  flex-direction: column;
}

.notes-section {
  flex: 1;
  margin-bottom: 13px;
  overflow-wrap: break-word;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.buttons {
  right: 0;
  bottom: 0;
  position: absolute;
}

.red .progress-bar {
  background-color: red;
}

.orange .progress-bar {
  background-color: orange;
}

.green .progress-bar {
  background-color: green;
}

.blue .progress-bar {
  background-color: blue;
}

.indigo .progress-bar {
  background-color: #000066;
}

.violet .progress-bar {
  background-color: #990099;
}

.purple .progress-bar {
  background-color: #660099;
}

.navbar-border {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.tab-pane {
  width: 100%;
}

.search-time-entries-btn {
  margin-left: 6px;
  margin-top: 0px;
}

.min-width-100 {
  min-width: 100%;
}

.timelogPageContainer {
  width: 100%;
  padding: 0 17%;
}

.active.dark-mode.nav-link {
  background-color: #1C1C1C !important;
  color: #fff !important;
  border-bottom: 0 !important;
}

@media screen and (max-width: 1400px) and (min-width: 1100px) {
  .timelogPageContainer {
    padding: 0 10%;
  }
}

@media screen and (max-width: 1100px) and (min-width: 800px) {
  .timelogPageContainer {
    padding: 0 9%;
    margin-left: 15px;
  }
}

@media screen and (max-width: 800px) {
  .timelogPageContainer {
    padding: 0 8%;
    margin-left: 15px;
  }
}

@media screen and (max-width: 575px) and (min-width: 399px) {
  .search-time-entries-btn {
    margin-top: 15px;
  }
}

@media (max-width: 544px) {
  .tab-pane {
    max-height: 1000px;
    overflow-y: scroll;
    width: 100%;
  }

  /* 
  .right-padding-temp-fix {
    padding-right: 0px !important;
  } */
}

#projectSelected {
  max-width: 100%;
}

#projectSelected option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right-padding-temp-fix {
  padding-right: 0px !important;
}

.button {
  margin-left: 10px;
  /* Add space between buttons */
  padding: 5px 10px;
  /* Add padding to the buttons */
  border: curved;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.edit-button {
  background-color: blue;
  /* Blue for the Edit button */
  color: white;
}

.save-button {
  background-color: green;
  /* Green for the Save button */
  color: white;
}

.cancel-button {
  background-color: red;
  /* Red for the Cancel button */
  color: white;
}

.edit-textarea {
  min-height: 150px;
  resize: vertical;
}

.activeUser {
  color: lawngreen;
}

.notActiveUser {
  color: #dee2e6;
}


.custom-text-alignment {
  text-align: center;
}


.tasks-and-timelog-header-row {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.tasks-and-timelog-header-add-time-div {
  float: right;
}

.taskboard-header-title {
  font-size: 1.8rem;
}

@media screen and (max-width: 1240px) {
  .tasks-and-timelog-header-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .tasks-and-timelog-header-add-time-div {
    float: none;
    margin-top: 10px;
  }

  .task-and-timelog-card-nav {
    font-size: .9rem;
  }
}

@media (min-width: 992px) {
  .custom-text-alignment {
    text-align: right;
  }
}


.container-timelog-wrapper {
  min-height: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 544px) {
  .responsive-font-size {
    font-size: 0.75rem !important;
  }
}

@media only screen and (max-width: 575px) {
  .date-selector-form {
    margin-left: 5px;
  }
}

/* Ensure the button content (text + icon) is aligned horizontally */
.followup-button {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Maintain spacing between text and icon */
}

/* Adjust the icon position to ensure it stays on the right */
.followup-tooltip-button {
  display: inline-flex;
  align-items: center;
}

/* Keep the icon aligned within the button */
.fa-info-circle {
  font-size: 14px;
  margin-left: 5px;
  /* Add spacing between text and icon */
}