.scrollAutoComplete::-webkit-scrollbar {
  width: 5px;
}

.scrollAutoComplete::-webkit-scrollbar-track {
  background-color: black;
}

.scrollAutoComplete::-webkit-scrollbar-thumb {
  background: rgb(0, 140, 255);
  border-radius: 8px;
}

.scrollAutoComplete::-webkit-scrollbar-thumb:hover {
  background: rgb(221, 221, 221);
}

.dropdown-item-hover:hover {
  background-color: #2f4157 !important;
}

@-moz-document url-prefix() {
  .scrollAutoComplete {
    scrollbar-width: thin;
    scrollbar-color: rgb(0, 140, 255) black;
  }
}