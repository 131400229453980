.input-text {
  position: relative;
}
.input-text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 15px;
  cursor: pointer;
}
.fa-eye {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 15px;
  cursor: pointer;
}