.leaflet-container {
  width: 100%;
  min-height: 500px;
  height: 90vh;
  z-index: 0; /* Will block navbar dropdowns otherwise */
}

.map-dropdown-table {
  background-color: white;
  padding: 0 5px 20px 20px;
  top: 100%;
  z-index: 10;
  overflow: hidden;
  max-height: 300px;
  width: auto;
}

#map-container {
  height: calc(100vh - 225px);
}

.text-and-table-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: baseline;

}

.cursor-pointer {
  cursor: pointer;
}

.small-column {
  width: 15px;
}

.medium-column {
  max-width: 124px;
  width: 124px;
}

.large-column {
  max-width: 100px;
}

.column-header {
  font-size: .75rem;
}

.column-content {
  width: 40px;
  height: 20px;
  cursor: pointer;
}

.map-table-container {
  position: absolute;
  top: 10px; 
  right: 10px; 
  z-index: 2; 
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  padding: 1px;
  padding-top: 0px;
  max-height: 65vh; 
  width: 90%; 
  max-width: 350px; 
  min-width: 300px;
  overflow-y: scroll; 
  overflow-x: hidden; 
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
  border-radius: 10px;
}

.map-table-container.visible {
  opacity: 1; 
}

@media screen and (max-width: 350px) {
  .map-table-container {
    display: none;
  }
}

.team-locations-table { 
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}


.team-locations-table-header {
  background-color: #282828 !important;
  color: white;
  padding: 10px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .map-table-container {
    width: 60%; 
    max-width: 60%; 
    max-height: 40vh;
  }

  .team-locations-table-header {
    background-color: #282828;
  }
} 

.team-locations-table-data {
  padding: 10px;
  text-align: left;
}


.team-locations-table-row-even {
  background-color: #f2f2f2;
}


.team-locations-table-row:hover {
  background-color: #cfcaca;
}

.team-locations-table thead th {
  position: sticky;
  top: 0;
  z-index: 100;
}

.search-bar {
  margin-bottom: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.search-bar.visible {
  opacity: 1;
}

.search-bar input[type='text'] {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 0.7rem;
}

.search-bar input[type='text']::placeholder {
  color: #888;
}

.dark-mode .leaflet-layer,
.dark-mode .leaflet-control-zoom-in,
.dark-mode .leaflet-control-zoom-out,
.dark-mode .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(85%) contrast(85%);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #fff;
}

.dark-mode .leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #3A506B;
  color: #fff;
}

/* Dark mode styles */
.team-locations-container.dark-mode {
  background-color: #0d1b2a !important;
  color: #fff !important;
}

.team-locations-container .dark-mode-input {
  background-color: #1a2b3c !important;
  color: #fff !important;
  border: 1px solid #4a4a4a !important;
}

.team-locations-container .dark-mode-dropdown {
  background-color: #1a2b3c !important;
  color: #fff !important;
}