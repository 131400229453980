#wrapper .report {
  position: absolute;
  right: 103%;
  top: 0;
  width: 200px;
  overflow-y: auto;
  visibility: hidden;
  font-weight: normal;
  border: 2px solid black;
  background-color: slategray;
}

#wrapper:hover .report {
  visibility: visible;
}

.title {
  padding: 5px;
  border-bottom: 2px solid black;
}

.summary {
  padding: 5px;
  white-space: pre-wrap;
}

.blueSquareContainer {
  text-decoration: none;
  justify-content: center;
  max-height: 120px;
  overflow: auto;
}

.blueSquareContainer::-webkit-scrollbar {
  width: 12px;
}

.blueSquareContainer::-webkit-scrollbar-track {
  background-color: #f8faff;
  border: 1px solid #cad9ff;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
}

.blueSquareContainer::-webkit-scrollbar-thumb {
  background-color: #007BFF ;
  box-shadow: inset 0 5px 3px -3px rgba(255, 255, 255, .6), inset 0 -5px 3px -3px rgba(255, 255, 255, .6);
}

.blueSquares {
  width: 100%;
  display: grid;
  gap: 2px;
  padding: 5px;
  grid-template-columns: repeat(auto-fill, minmax(25px, 1fr) ) ;
  align-items: center;
  justify-items: center;
}
.NoBlueSquares{
  grid-template-columns: auto 1fr;
  justify-items: left;
}

.blueSquareButton {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
  background-color: dodgerblue;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  margin: 5px;
  width: 20px;
  height: 20px;
}

.blueSquareButton:hover {
  background: linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
  background-color: #0061a7;
}

.blueSquareButtonDark {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #00a6ff 5%, #007ad1 100%);
  background-color: rgb(69, 162, 255);
  border-radius: 4px;

  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  margin: 5px;
  width: 20px;
  height: 20px;
}

.blueSquareButtonDark:hover {
  background: linear-gradient(to bottom, #1da1ff 5%, #23b2ff 100%);
  background-color: #1da1ff;
}

@media (max-width: 1420px) {
  #wrapper .report { 
     left: 102%;
     top: 0px; 
     z-index: 1;
     overflow: visible;
   }
   .summary {
     min-height: 300px;
   }
 }

 @media (max-width: 767px) {
  #wrapper .report { 
    left: 50%;
    top: 102%;
    transform: translate(-50%);
    width: 300px;
  }
  .summary {
    min-height: auto;
  }
 }
