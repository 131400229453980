.items_list_container {
  width: 100%;
  max-width: 1536px;
  margin: 1rem auto;
  padding: 0 1rem;
}

.items_table_container {
  overflow-x: scroll;
}

.items_list_container section {
  margin-top: 2rem;
}

.items_list_container table {
  text-align: center;
  min-width: 1024px;
  overflow: scroll;
  font-size: small;
}

.items_list_container th {
  height: 2rem;
}

.items_cell button {
  cursor: pointer;
}

.items_cell svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  color: dimgray;
}

.items_cell svg:hover {
  color: black;
}

.select_input {
  display: flex;
  align-items: flex-end;
  width: 300px;
  column-gap: 1rem;
}

.condition_cell {
  padding-left: 15px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
