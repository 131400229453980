.progress-hgn-container {
  width: 25%;
  margin: 0 auto;
  text-align: center;
}

.progress-hgn-bar {
  width: 100%;
  background-color: #363940;
  border-radius: 25px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-hgn {
  height: 10px;
  background-color: #1c8cc3;
  border-radius: 10px;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
