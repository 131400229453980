
.form-container{
  
  .form-control {
   max-width: unset !important;
   font-size: small !important;
 } 
 .form-label{
  margin: 5px 0;
  font-size: small;
  }
}

.form-control-delete {
  margin-left: 10px;
  font-size: small !important;
  max-width: 100%;
}

.tag .delete-tag {
  margin-left: 10px;
  background-color: red;
  
}

.main-container{
 display: flex;
 flex-direction: column;
 height: 100%;
   padding-left: 10%;
   padding-right: 10%;
   padding-top: 5%;
   padding-bottom: 1%;
}
.lesson-label{
   font-size: larger !important;
   font-weight: bold ;
}
.form-select-container{
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   font-size: small;
}

.single-form {
  display: flex !important;
  gap: 5px;
  text-align: center !important;
  margin-right: 40px;
  width: 200px;
  
 
}
.single-form-select {
  background-color: #F2F2F266 !important;

}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  margin-top: 10px;
}

.delete-input-wrapper {
  position: relative;
  flex: 1;
}

.form-control-delete {
  width: 100%;
  margin-left: 0;
  font-size: small !important;
}

.tags-input-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #F2F2F266;
  border-radius: 5px;
  padding: 2px 5px;
  margin: 2px;
  font-size: small;
  gap: 5px;
  min-height: 24px;
}

.tag .close {
  margin-left: 5px;
  cursor: pointer;
} 

.tag .button-close {
  background-color: #F2F2F266;
  border-color: #F2F2F266;
  color: black;
}

.tags-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}


.tag-dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
}

.tag-dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.tag-dropdown-item:hover {
  background-color: #f0f0f0;
}

  @media (max-width: 570px) {
   .form-select-container {
     flex-direction: column;
   }
  }

