.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-shrink: 0;
  object-fit: cover;
}

.carousel-image-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.carousel-image-arrow.left {
  left: 10px;
}

.carousel-image-arrow.right {
  right: 10px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  bottom: 2% !important;
}

.indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #333;
}