.icon-add-person {
    padding: 50px;
    justify-content: center;
    text-align: center;
}

.title-member {
    color: #f49441;
}

.container-add {
    background-color: white;
    border-radius: 10%;
    padding-bottom: 50px;
}

/* Name Section */
.name-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin: 20px auto;
    width: 500px;
}

.input-name {
    display: flex;
    flex-direction: column;
    width: 230px;
}

.input-name input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    height: 38px;
}

.input-name label {
    margin-bottom: 5px;
    text-align: left;
}

/* Role Section */
.role-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    width: 500px;
    margin: 20px auto;
}

.role-input {
    display: flex;
    flex-direction: column;
    width: 230px;
}

.role-input label {
    margin-bottom: 5px;
    text-align: left;
}

.role-input input,
.role-input .css-b62m3t-container {
    width: 100%;
    box-sizing: border-box;
}

/* Team Section */
.team-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    width: 500px;
    margin: 20px auto;
}

.team-input {
    display: flex;
    flex-direction: column;
    width: 230px;
}

.team-input label {
    margin-bottom: 5px;
    text-align: left;
}

.team-input input,
.team-input .css-b62m3t-container {
    width: 100%;
    box-sizing: border-box;
}

/* Contact Info Section */
.contact-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 20px;
    width: 500px;
    margin: 0 auto;
}

.contact-info label {
    margin-bottom: 5px;
    text-align: left;
}

.contact-info input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    height: 38px;
}

.phone-input-group {
    display: flex;
    gap: 10px;
}

.country-code-select {
    width: 150px;
}

/* Button Section */
.submit-cancel {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 60px;
}

.submit-button,
.cancel-button {
    padding: 15px 40px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 0px 50px;
}

.submit-button {
    background-color: #26505e;
    color: white;
    border: none;
}

.cancel-button {
    background-color: white;
    border: 2px solid #26505e;
    color: black;
}

/* Error Styles */
.error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}