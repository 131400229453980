.header-wrapper {
  height: fit-content;
  width: clamp(100vw, 0.1rem + 1vw, 100%);
}

.close-button {
  height : 2vh;
  width : 99%;
}

.card-content {
  padding: 5px; /* Add padding around the card content */
  padding-left: 40px;
  padding-right: 40px;
  white-space: pre-line;
  color: white;
  font-size: medium;
}

.navbar {
  /* z-index: 100; */
  white-space: nowrap;
  /* margin-bottom: 20px; */
}

.timer-message-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.redBackGroupHeader {
  z-index: 10;
  bottom: 0;
  right: 0;
  position: relative;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  background: #ff4d4f;
  border-radius: 40px;
  color: #fff;
  padding: 3px 6px;
  max-width: 39px;
  font-size: 1rem;
  min-width: 29px;
}

.owner-message {
  margin-right: 3rem;
}

.nav-links {
  display: flex;
  align-items: center;
}

.dropdown-item-hover:hover {
  background-color: #2f4157 !important;
}

@media (max-width: 1400px) {
  .nav-links {
    flex-direction: column !important;
  }
}

@media (max-width: 1500px) {
  .dashboard-text-link {
    font-size: 14px;
  }

  .owner-message {
    margin-right: 0;
  }
}

@media (max-width: 1050px) {
  .timer-message-section {
    display: flex;
    width: 0;
    margin-right: 0;
  }

  .owner-message {
    display: none;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  /* .owner-message {
    display: none;
  } */
}

@media screen and (max-width: 769px) {
  .responsive-spacing {
    margin-right: 5px;
  }
}
.header-margin{
  height : 20px;
  background-color: #1B2A41 ;
}
.header-margin-light{
  height : 20px;
  background-color: #fff;
}