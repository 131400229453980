.drag-drop-container {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.update-confirm-text {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.cancel-button {
  width: 100%;
}

.submit-button {
  width: 100%;
}

.square-image {
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 4px;
}

.square-image {
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 4px;
}

.read-only-div {
  padding: 8px;
  background-color: #e6f5f2;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  color: #495057;
}
