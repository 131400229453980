.job-cc-dashboard {
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.dark-mode-job-cc-dashboard {
  background-color: #2c2f33;
  color: #ffffff;
}

.dashboard-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.form-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.dark-mode-job-cc-dashboard .form-container {
  background-color: #3c3f44;
  color: #ffffff;
}

.form-container form {
  max-width: 500px;
  margin: 0 auto;
}

.list-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.cc-list-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dark-mode-job-cc-dashboard .cc-list-container {
  background-color: #3c3f44;
}

.job-cc-dashboard-table {
  margin-top: 10px;
}

.job-cc-dashboard-table th {
  background-color: #007bff;
  color: #ffffff;
}

.dark-mode-job-cc-dashboard .job-cc-dashboard-table th {
  background-color: #0056b3;
}

.job-cc-dashboard-table td {
  vertical-align: middle;
}

.job-cc-dashboard-table button {
  font-size: 0.85rem;
}
