.warnings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warning-item-container {
  display: flex;
  flex-direction: column;
}
.warning-wrapper {
  max-height: 200px;
  max-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.icons-wrapper {
  display: flex;
}
.warning-icons {
  display: flex;
}

.warning-text {
  text-align: center;
  font-size: 1em;
  margin: 0;
  margin-bottom: 0.3em;
}

.popover-body {
  text-align: center;
}

.error-container {
  /* max-height: 200px; */
  max-width: 150px;
  display: flex;
}

.warning-modal-footer {
  /* display: grid !important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* justify-content: center !important; */
  /* grid-template-columns: 1fr 1fr !important; */
  /* grid-gap: 1em !important; */
}
.warning__body--bold {
  font-weight: bold;
}
.warning__body--margin {
  margin: 0;
}
.warning__modal__footer__btn {
  width: 40%;
}

.button__container {
  display: flex;
  margin-top: 0.1em;
}

.tracking__btn {
  margin-right: 0.3em;
}
.warnings__descriptions {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
  text-align: left;

  padding: 0.5em 1em;
  background: rgb(245, 244, 244);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 0px;
}
.warning__descriptions__btn {
  padding: 0 !important;
  font-size: 1rem;
  width: 30px !important;
  height: 30px !important;
  margin: 0em 0.2em !important;
  width: auto;
  border-radius: 5px;
  margin: 0;
}
.warnings__descriptions__title {
  text-transform: capitalize;
  border: none;
  background: none;
  width: 300px;
  margin: 0 0.5em;
  font-weight: bold;
  font-size: 1em;
}

.warnings__descriptions__title:disabled {
  cursor: not-allowed;
}

.warnings__descriptions__title:focus {
  border: 1px solid red;
}
.warnings__descriptions__title--gray {
  opacity: 0.6;
}

.btn__container {
  display: flex;
  justify-content: center;
}

.add__btn {
  margin-top: 0.5em;
  padding: 0.3em 0.5em !important;
}

.form__btn__add {
  margin-left: 0.5em;
}

.warning__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em 1em;
  max-width: 500px;
  width: 70%;
  margin-top: 0.5em;
  box-shadow: lightgray 2px 2px 4px 1px;
}
.warning__form__title {
  font-weight: bold;
}
.warning__title {
  margin: 0.3em 0;
  text-align: left;
}
.warning__permanent {
  margin: 0.5em 0.5em;
}

.warning__form__btns {
  margin-top: 1em;
}
.warning__input {
  border-radius: 3px;
  border: 1px solid #000;
}
.warning__reorder {
  width: 18px;
  height: 18px;
  cursor: grab;
  margin-right: 1em;
}

.modal__information {
  text-align: left;
  font-size: 1.2rem;
  font-weight: semi-bold;
  margin: 0.5em 0;
}

.alert__container {
  margin-top: 0.5em;
  text-align: center;
}

.modal__warning__deletion {
  font-weight: bold;
  font-size: 1.3rem;
}
