.banner {
  width: 50%;
  margin: 10px auto;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner img {
  width: 1000px;
  height: 200px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 15px;
  border-color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  .banner {
    width: 80%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .banner {
    width: 90%;
    margin: 5px auto;
  }
}
