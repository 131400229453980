.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.crossIcon {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
}

.countdown .infoDisplay {
  font-size: 1.5rem;
  text-align: center;
}

.resetIcon {
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.countdownCircle {
  width: 90%;
}

.countdownCircle .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content .remainingTime {
  position: relative;
  display: flex;
  gap: 0.05rem;
  justify-content: center;
}

.content .timeDisplay {
  min-width: 3rem;
  font-weight: bolder;
  font-size: 2rem;
  margin-top: -0.5rem;
  text-align: center;
}

.content .label {
  font-size: 0.7rem;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
}

.content .timeColon {
  margin-top: -0.2rem;
  font-size: 1.7rem;
  font-weight: bolder;
}

.content .operators {
  display: flex;
  justify-content: space-between;
}

.operators button{
  background-color: #343a40;
  border: none;
}

.operators .operator {
  color: white;
  cursor: pointer;
}

.operators .operatorDisabled {
  color: #837175;
  cursor: not-allowed;
}

.countdown .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bottom .addGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.addGrid button{
  padding: 0;
  margin:0;
  border:none;
  height: 100%;
  background-color: #343a40;
}

.addGrid span{
  /* padding: 0; */
  border:none;
}

.btn {
  color: white;
  font-size: 0.9rem;
  background-color: rgba(82, 92, 102, 1);
  padding: 0.5rem;
  border: none;
  border-radius: 0.375rem;
}

.btn:focus {
  box-shadow: none;
}

.btn:hover {
  background-color: rgba(82, 92, 102, 0.8);
}

.btnDisabled {
  cursor: not-allowed;
  color: #837175;
  background-color: rgba(82, 92, 102, 0.4);
}

.bottom .goal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 4.5rem;
  max-height: 2.5rem;
  color: white;
  background-color: rgba(82, 92, 102, 1);
  border-radius: 0.5rem;
  user-select: none;
  transition: all 0.2s ease;
}

.bottom .goalEditing {
  width: 4.5rem;
  max-height: 3rem;
}

.bottom .goal input {
  border: none;
  background-color: transparent;
  height: 3rem;
  width: 2rem;
  color: white;
  outline: none;
  padding: 0;
  text-align: center;
}

.goal .numberWrapper {
  position: relative;
  line-height: 1rem;
}

.up {
  position: absolute;
  top: 0.2rem;
  left: 50%;
  transform: translate(-50%, -10%);
  cursor: pointer;
}

.down {
  position: absolute;
  bottom: 0.2rem;
  left: 50%;
  transform: translate(-50%, 20%);
  cursor: pointer;
}

.bottom .goal input[type='number']::-webkit-inner-spin-button,
.bottom .goal input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in number input for Firefox and other browsers */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* Hide spin buttons in number input for Firefox */
input[type='number']::-moz-inner-spin-button {
  display: none;
}

.bottom .goal input:focus {
  outline: none;
  box-shadow: none;
}

.goal .goalBtn {
  position: absolute;
  right: -1.5rem;
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  cursor: pointer;
}

.timerStatus {
  position: absolute;
  z-index: 40;
  top: 50%;
  left: 50%;
  width: 85%;
  white-space: normal;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.transitionColor {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
