.bm-dashboard__header h1 {
  font-size: 1.5em;
  text-align: center;
  margin-top: 2em;
}

.bm-dashboard__button.btn.btn-secondary {
  background-color: #2e5061;
}

@media (min-width: 650px) {
  .bm-dashboard__button.btn.btn-secondary {
    padding-left: 0;
    padding-right: 0;
    max-height: 38px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1em;
}

.projects-list {
  width: 100%;
  max-width: 1200px;
  list-style-type: none;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 1em auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.project-summary {
  background-color: #e8f4f9;
  margin: 1em;
  padding: 1em;
  border-radius: 5px;
  transition: transform 0.2s;
}

.project-summary:hover {
  transform: scale(1.02);
}

.project-summary_header {
  font-size: clamp(0.8em, 3vw, 1.3em);
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.project-summary_content {
  display: block;
  flex-wrap: wrap;
  text-align: start;
  margin: 0;
  font-size: clamp(0.9em, 2.5vw, 1em);
}

.project-summary_item {
  padding: 8px;
  align-items: center;
}

.project-summary_label {
  margin-right: 1px;
}

.project-summary_span {
  color: #3ea0cb;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
}

.bm-error-page {
  width: 100%;
  max-width: 1536px;
  margin: 1rem auto;
  padding: 0 1rem;
}

.bm-error-page section {
  margin-top: 2rem;
}

.inv-form-page-container {
  width: 100%;
  max-width: 800px;
  margin: 1rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 40px;
}

.inv-form {
  margin: 2rem auto;
}

.inv-form-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 1rem 0;
}

.inv-form-group {
  margin: 1.5rem auto;
}

.inv-form input,
.inv-form select,
.inv-form textarea {
  background-color: rgb(249, 249, 249);
}

.inv-form-btn-group {
  display: flex;
  gap: 1rem;
  margin: 4rem auto 2rem;
}

.inv-form-btn-group button {
  width: 50%;
}

.inv-form-required::after {
  content: '*';
  color: red;
}

@media screen and (max-width: 800px) {
  .inv-form-page-container {
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .inv-form-page-container h2 {
    font-size: 1.7rem;
  }
}

.form-footer {
  font-size: 0.875em;
  margin-top: 3px;
}

.projects-list::-webkit-scrollbar {
  width: 8px;
}

.projects-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.projects-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.projects-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
