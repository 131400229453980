.confirmationContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.envelope {
  width: 100px;
  height: 100px;
  background: url('./envelope.png') no-repeat center center;
  background-size: contain;
}

.iconContainer {
  display: inline-block; 
  animation: iconAppear 1s ease-in-out forwards;
}

@keyframes iconAppear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.checkmark {
  color: white; /* Set the color of the checkmark to white */
  font-size: 25px;
  line-height: 100px; /* Adjust line-height to the height of the iconContainer for vertical alignment */
  
  /* Create a circle */
  width: 50px; /* Width of the circle */
  height: 50px; /* Height of the circle */
  background-color: #4BB543; /* Background color of the circle */
  border-radius: 50%; /* Round the corners to create a circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.crossmark {
  color: white; /* Set the color of the checkmark to white */
  font-size: 25px;
  line-height: 100px; /* Adjust line-height to the height of the iconContainer for vertical alignment */
  
  /* Create a circle */
  width: 50px; /* Width of the circle */
  height: 50px; /* Height of the circle */
  background-color: #9e7e7e; /* Background color of the circle */
  border-radius: 50%; /* Round the corners to create a circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsContainer {
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.messageContainer {
  /* Styles for other messages */
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.oneCommunityIcon {
  width: 648px;
  height: 200px;
  border-radius: 5px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  background: url('./HGN-Your-Email-Has-Been-Verified-648x200px.jpg') no-repeat center center;
  background-size: cover;
}
/* Rest of the styles for text and layout */
