#file-upload-form{
  height: 16rem;
  width: 100%;
  text-align: center;
  position: relative;
}

#file-upload-input{
    display: none;
}

#file-upload-label{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #ffffff;
}

#file-upload-label.drag-active{
  background-color: #f8fafc;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

/* .file-preview-container{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  height: 10vh; 
} */