.popup_container {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0%;
  margin: 0;
  text-align: center;
  background-color: #ffcc00;
  color: #fff;
  z-index: 2;
  opacity: 0.8;
}

.close_button {
  background: none;
  border: none;
  font-size: 18px;
  color: #000000;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}