/* ---------------- PAGE CONTAINER ---------------- */
.weekly-project-summary-container {
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.weekly-project-summary-container html,
.weekly-project-summary-container body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.weekly-project-summary-dashboard-container {
  width: 100%;
  background: var(--bg-color);
}

.weekly-project-summary-header-wrapper {
  background-color: var(--bg-color) !important;
}

/* ---------------- HEADER ---------------- */
.weekly-summary-header-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Header Container */
.weekly-summary-header-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 16px;
  background: var(--section-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Title */
.weekly-summary-header-title {
  padding-top: 5px;
  color: var(--text-color);
  font-size: x-large;
  text-align: center;
  flex-wrap: wrap;
}

/* Header Controls */
.weekly-summary-header-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Dropdown Styling */
.weekly-summary-header-controls select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  min-width: 200px;
  font-size: 14px;
  background: var(--section-bg);
  color: var(--text-color);
}

/* Dropdown Hover */
.weekly-summary-header-controls select:hover {
  background-color: var(--section-title-hover) !important;
}

/* Share Button */
.weekly-summary-share-btn {
  background-color: var(--button-bg) !important;
  color: white;
  font-family: inherit;
  white-space: nowrap;
  border-radius: 8px !important;
  transition: background 0.2s ease-in-out;
}

.weekly-summary-share-btn:hover {
  background-color: var(--button-hover) !important;
}

/* ---------------- DASHBOARD ---------------- */
.weekly-project-summary-dashboard-container {
  width: 95%;
  margin: auto;
  padding: 20px;
  background: var(--bg-color);
  color: var(--text-color);
}

.weekly-project-summary-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.weekly-project-summary-dashboard-section.full {
  grid-column: span 4;
}

.weekly-project-summary-dashboard-section.large {
  grid-column: span 3;
}

.weekly-project-summary-dashboard-section.half {
  grid-column: span 2;
}

.weekly-project-summary-dashboard-section.small {
  grid-column: span 1;
}

/* ---------------- CARDS ---------------- */
.project-status-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
}

.weekly-project-summary-card {
  background: var(--card-bg);
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 2px 5px var(--card-shadow);
}

.dark-mode .weekly-project-summary-card {
  background: var(--card-bg);
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.financial-small {
  min-height: 100px;
  max-height: 120px;
}

.financial-big {
  grid-column: span 2;
  min-height: 250px;
}

.wide-card {
  width: 100%;
  min-height: 250px;
  grid-column: span 2;
}

.small-card {
  width: 100%;
  min-height: 100px;
}

.normal-card {
  width: 100%;
  min-height: 250px;
}

.weekly-project-summary-dashboard-section {
  background: var(--section-bg);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px var(--card-shadow);
}

.weekly-project-summary-dashboard-category-title {
  background: var(--section-title-bg);
  padding: 12px 16px;
  font-size: medium;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}

.weekly-project-summary-dashboard-category-title:hover {
  background: var(--section-title-hover);
}

.weekly-project-summary-dashboard-category-content {
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  border-top: 1px solid var(--card-shadow);
  animation: fadeIn 0.3s ease-in-out;
  background: var(--section-bg);
}

/* ---------------- DARK MODE VARIABLES ---------------- */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --card-bg: #fafafa;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --section-bg: white;
  --section-title-bg: white;
  --section-title-hover: #e0e0e0;
  --button-bg: black;
  --button-hover: #333;
}

/* ---------------- DARK MODE STYLES ---------------- */
.dark-mode {
  --bg-color: #1b2a41;
  --text-color: #ffffff;
  --card-bg: #2b3e59;
  --card-shadow: rgba(255, 255, 255, 0.1);
  --section-bg: #253342;
  --section-title-bg: #2d4059;
  --section-title-hover: #3a506b;
  --button-bg: #e8a71c;
  --button-hover: #f5b13a;
}

.dark-mode .weekly-project-summary-dashboard-category-title {
  color: #ffffff;
  background: #2d4059;
}

.dark-mode .weekly-project-summary-dashboard-category-title:hover {
  background: #3a506b;
}

.dark-mode .weekly-project-summary-dashboard-category-content {
  background-color: var(--section-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-mode .weekly-summary-header-container {
  background: var(--section-bg);
  color: var(--text-color);
}

.weekly-summary-share-btn {
  background-color: var(--button-bg) !important;
}

.weekly-summary-share-btn:hover {
  background-color: var(--button-hover) !important;
}

/* ---------------- RESPONSIVE DESIGN ---------------- */

/* Medium Screens - Wrap Items */
@media (max-width: 1024px) {
  .weekly-summary-header-container {
    flex-direction: column;
    align-items: center;
  }

  .weekly-summary-header-controls {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .weekly-project-summary-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small Screens - Make Dropdowns Vertical */
@media (max-width: 768px) {
  .weekly-summary-header-container {
    flex-direction: column;
    align-items: center;
  }

  .weekly-summary-header-controls {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  /* Make Dropdowns Full Width */
  .weekly-summary-header-controls select {
    width: 100%;
  }

  /* Make Button Full Width */
  .weekly-summary-share-btn {
    width: 100%;
  }

  .weekly-project-summary-dashboard-grid {
    grid-template-columns: 1fr;
  }
}