.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0e0e0;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 30%;
  height: auto;
  min-width: 250px;
}
.content-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 85%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: max-content;
}

.container-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #9dd425;
}

.container-main {
  background-color: #e0e0e0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 40px 30px 40px;
  padding: 20px 0px 20px 0px;
  width: auto;
  min-height: 560px;
  height: auto;
}

.details-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5%;
}

.details-right {
  width: 50%;
  height: 100%;
  padding: 2.5%;
}

.mobile-display {
  display: none;
}

.image-carousel {
  width: 100%;
  margin-bottom: 2.5%;
}

.amenities {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.amenities h2 {
  font-size: 16px;
  text-align: left;
  color: #060606;
  font-weight: bold;
}

.amenities div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5%;
}

ol {
  display: flex;
  flex-direction: column;
  list-style-position: inside;
}
li {
  margin: 0;
}

.location {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: flex-end;
  margin: 2%;
  padding-left: 5%;
}

.location img {
  width: 30px;
  height: 30px;
}

.location a {
  text-decoration: underline;
  cursor: pointer;
}

.listing-details h1,
p {
  color: #000;
}

.rent-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.rent-form label {
  display: flex;
  width: 150px;
  flex-direction: column;
}

.rent-form button {
  background: #13ae5c;
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.rent-form button:hover {
  background: #0f9a50;
  transform: scale(1.01);
}

.rent-form button:active {
  transform: scale(0.99);
}
.rent-form input[type='date'] {
  padding: 5% 10%;
  border: 1px solid #ccc;
  margin-top: 2.5%;
  width: 100%;
}

.chat-host {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2.5%;
}

.chat-host button {
  background: #d1d1d1;
  padding: 2.5%;
  border-radius: 998px;
  border: none;
  width: 33%;
  display: flex;
  justify-content: center;
  min-width: 200px;
  gap: 10%;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

@media (max-width: 990px) {
  .main-container {
    padding: 0;
  }
  .container-main {
    flex-direction: column;
    align-items: center;
  }
  .details-left {
    width: 98%;
  }
  .details-right {
    width: 98%;
  }

  .mobile-display {
    display: block;
  }
  .desktop-display {
    display: none;
  }
  .chat-host button {
    background: #d1d1d1;
    padding: 2.5%;
    border-radius: 998px;
    border: none;
    width: 45%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 580px) {
  
  .container-main {
    padding: 10px;
    margin: 10px;
  }
  .amenities {
    flex-direction: column;
    align-items: center;
  }
  .rent-form label {
    display: flex;
    width: 150px;
    flex-direction: column;
  }
  .rent-form input[type='date'] {
    padding: 3% 3%;
    border: 1px solid #ccc;
    margin-top: 2.5%;
    width: 90%;
  }
  .chat-host button {
    background: #d1d1d1;
    padding: 2.5%;
    border-radius: 998px;
    border: none;
    width: 60%;
    display: flex;
    justify-content: center;
    min-width: 90px;
    font-size: 12px;
  }
}
