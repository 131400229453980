
.toggleTeamsVisibility {
    height: 21px;
    width: 36px;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    margin: 5px;
    background: dodgerblue;
    transition: all 0.5s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: darkslategrey;
      background: white;
      box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
      transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    }
    &:checked {
      // background: darkslategrey;
      background: dodgerblue;
  
      &:after {
        transform: translatex(15px);
        // transform: translatex(0);
  
      }
    }
    &:not(:checked) {
      background: darkslategrey; 
    }
  
  }
  
  .switchSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #0062cc;
    font-weight: bold;
    margin: 5px;
  }
  
  .switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  /* Team Memeber Popup styles */
  .divContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider {
    width: 100px;
    -webkit-appearance: none;
    appearance: none;
    height: 5px;
    border-radius: 5px;
    background: var(--track-color); /* Dynamic background color for the slider track */
    outline: none;
    margin-bottom: 10px;
    transition: background 0.3s ease; /* Smooth transition for background color */
  }
  
  /* Custom slider thumb */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: var(--thumb-color); /* Dynamic thumb color based on state */
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for thumb color */
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: var(--thumb-color); /* Dynamic thumb color based on state */
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for thumb color */
  }
  
  .sliderLabels {
    display: flex;
    justify-content: space-between;
    width: 100px;
    font-size: 12px;
  }
  
  .sliderLabels span {
    font-weight: normal;
    color: gray;
    transition: color 0.3s ease;
  }
  
  .activeLabel {
    font-weight: bold;
    color: black;
  }
  