.leaderboard {
  font-size: 1rem;
} /*1rem = 16px*/

.my-custom-scrollbar {
  overflow-y: auto;
  margin-bottom: 1rem;
}

.leaderboard thead th {
  top: 0;
  border-top: 10px solid #dee2e6;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(222, 226, 230);
}
.dark-leaderboard-row {
  background-color: #3a506b;
  color: white;
}

.dark-leaderboard-row th {
  background-color: #3a506b;
  color: white;
}
.dark-leaderboard-row:hover {
  background-color: #111831;
}

.light-leaderboard-row {
  background-color: white;
  color: black;
  display: table-row;
}
.light-leaderboard-row th {
  background-color: white;
  color: black;
}

.light-leaderboard-row:hover {
  background-color: #f0f8ff;
}


/* Small devices (landscape phones, 544px and up) */
@media (max-width: 544px) {
  .leaderboard {
    font-size: 0.7rem;
  } /*1rem = 16px*/

  .my-custom-scrollbar {
    max-height: 500px;
    overflow: scroll;
    margin-bottom: 1rem;
  }

  .leaderboard thead th {
    top: 0;
    border-top: 10px solid #dee2e6;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(222, 226, 230);
  }
}
.row {
  width: 97%;
}

.table-fixed tbody td {
  white-space: normal; /* Allow text wrapping within cells */
}

.table-fixed {
  font-size: 0.93rem;
  word-break: break-word;
}

.animation {
  animation: l-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes l-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaderboard-totals-title {
  color: #339cff;
  font-weight: bold;
}

.leaderboard-totals-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 544px) {
  .responsive-font-size {
    font-size: 0.75rem !important;
  }
}


.leaderboard.abbreviated-mode thead th {
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  min-width: 70px;
  padding: 0.5rem 0.3rem;
  vertical-align: middle;
}


