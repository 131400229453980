body {
  font-family: 'Poppins', sans-serif;
  background: #fff;
  margin: 0;
  padding: 0;
}

.dashboard-container {
  padding: 40px 20px;
  max-width: 1400px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background: linear-gradient(120deg, #ffffff, #f8f9fa);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dashboard-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
}

.dashboard-search-container input {
  border: 2px solid #2c3e50;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 1rem;
  width: 100%;
  max-width: 600px;
  transition: all 0.3s ease;
}

.dashboard-search-container input:focus {
  border-color: #34495e;
  box-shadow: 0 0 8px rgba(52, 73, 94, 0.3);
  outline: none;
}

.dashboard-sidebar {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.filter-section h4 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 600;
}

.filter-item input,
.filter-item select {
  padding: 12px 15px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  font-size: 1rem;
  background: #ffffff;
  transition: all 0.3s ease;
}

.filter-item input:focus,
.filter-item select:focus {
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 62, 80, 0.4);
  outline: none;
}

.dashboard-main {
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 2.2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.event-card {
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.event-card-img-container img {
  width: 100%;
  height: auto;
  border-bottom: 3px solid #34495e;
}

.event-title {
  text-align: center;
  color: #2c3e50;
  margin: 10px 0;
  font-weight: bold;
  font-size: 1.5rem;
}

.event-date,
.event-location,
.event-organizer {
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 5px 0;
}

.dashboard-actions {
  text-align: center;
  margin-top: 20px;
}

.dashboard-actions button {
  background-color: #2c3e50;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
}

.dashboard-actions button:hover {
  background-color: #34495e;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
