.badge_summary_viz_footer {
  width: fit-content;
  float: right;
}

.badge_summary_viz_button {
  box-shadow: '2px 2px 4px 1px lightgray';
  margin: 5px;
}

@media screen and (max-width: 1024px) {
  .badge_summary_viz_footer {
    width: 100%;
  }
  .badge_summary_viz_button {
    width: 100%;
    float: none;
    margin: 5px 0;
  }
}

.badge_count_viz {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #007bff;
  color: #fff;
  text-align: center;
  font: 15px Arial, sans-serif;
  position: absolute;
  right: 28px;
  bottom: 2px;
  z-index: 10;
}

.badge_count_personalmax_viz {
	padding-top: 4px;
	border-radius: 45%;
	width: 19px;
	height: 19px;
	background: #800080;
	color: #fff;
	text-align: center;
	font: 11px Arial, sans-serif;
	position: absolute;
	right: 28px;
	bottom: 3px;
	z-index: 10;
}

.badge_count_3_digit_viz {
  border-radius: 50%;
  width: 20px;
  height: 15px;
  background: #007bff;
  color: #fff;
  text-align: center;
  font: 11px Arial, sans-serif;
  position: absolute;
  right: 28px;
  bottom: 2px;
  z-index: 10;
}
